<template>
<div>
  <h6
      class="text-left justify-start body-1 font-weight-bold px-3 my-6"
  >
    {{$t('delivery.lang_address')}}
  </h6>
  <v-card outlined>
    <v-row justify="center">
      <v-expansion-panels accordion >
        <v-progress-linear height="10" rounded color="primary" indeterminate v-if="loading" />
        <template v-if="addresses" >
          <v-expansion-panel
              v-for="(address) in addresses"
              :key="address.id"
          >
            <v-expansion-panel-header>{{address.name}}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" >
                  <h6 class="text-center mb-6">
                    {{$t('delivery.lang_whereShouldOrderGo')}}
                  </h6>
                </v-col>

                <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style="margin-top: -25px;"
                >
                  <span class="caption">{{$t('delivery.lang_street')}}</span>
                  <div>
                    <v-text-field
                        v-model="address.street"
                        @keypress="checkPattern"
                        name="street"
                        placeholder="Schauenburgerstraße"
                        outlined
                        dense
                    ></v-text-field>
                  </div>
                </v-col>

                <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style="margin-top: -25px;"
                >
                  <span class="caption">{{$t('delivery.lang_houseNumber')}}</span>
                  <div>
                    <v-text-field
                        v-model="address.streetNumber"
                        name="streetNumber"
                        placeholder="116"
                        outlined
                        dense
                    ></v-text-field>
                  </div>
                </v-col>

                <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style="margin-top: -25px;"
                >
                  <span class="caption">{{$t('delivery.lang_postalCode')}}</span>
                  <div>
                    <v-text-field
                        v-model="address.zip"
                        type="number"
                        name="zip"
                        placeholder="24118"
                        outlined
                        dense
                    ></v-text-field>
                  </div>
                </v-col>

                <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style="margin-top: -25px;"
                >
                  <span class="caption">{{$t('delivery.lang_City')}}</span>
                  <div>
                    <v-text-field
                        v-model="address.city"
                        @keypress="checkPattern"
                        name="city"
                        placeholder="Kiel"
                        outlined
                        dense
                    ></v-text-field>
                  </div>
                </v-col>

                <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style="margin-top: -25px;"
                >
                  <span class="caption">{{$t('delivery.lang_floor')}}</span>
                  <div>
                    <v-text-field
                        v-model="address.floor"
                        name="floor"
                        placeholder=""
                        outlined
                        dense
                        width="50%"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style="margin-top: -25px;"
                >
                  <v-checkbox v-model="address.default"
                              name="floor" :disabled="address.default===1" :readonly="address.default===1"
                              outlined @change="setDefault(address)"
                              dense label="Standard"/>
                </v-col>
                <v-col cols="12" v-if="address.id===0">
                  <v-btn @click="addAddress(address)" :loading="loadingAdd" color="accent">{{$t("delivery.lang_add")}}</v-btn>
                </v-col>
                <v-col cols="12" v-else>
                  <v-btn @click="updateAddress(address)" :loading="loadingUp" color="accent">{{ $t('delivery.lang_account_update') }}</v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>

            <template v-if="address.default" v-slot:actions>
              <v-icon color="teal">
                mdi-check
              </v-icon>
            </template>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
    </v-row>
  </v-card>
</div>
</template>

<script>
import validation from "../../mixins/validation";

export default {
  name: "CustomerAddresses",
  mixins:[validation],
  data(){
    return{
      loading:false,
      loadingAdd:false,
      loadingUp:false,
      addresses:null,
      street:null,
      streetNumber:null,
      zip:null,
      city:null,
      floor:null,
    }
  },
  methods:{
    getUserAddress(){
      this.loading=true;
      this.addresses=[];
      this.axios.get('localbee/v2/account/address').then(res=>{
        if(res.status===200){

          this.addresses.push(...res.data.data.map(address=>{

            return {
              id:address.uuid,
              name:address.floor+" "+address.street+" "+address.streetNumber+" "+address.zip+" "+address.city,
              street:address.street,
              streetNumber:address.streetNumber,
              zip:address.zip,
              city:address.city,
              floor:address.floor,
              default:address.default,
            }
          }))
          this.addresses.push({
            id:0,
            name:this.$t('delivery.lang_addAddress'),
            street:null,
            streetNumber:null,
            zip:null,
            city:null,
            floor:null,
            default:null,
          });
        }else{
          console.log(res)
        }
      }).catch(err=>{
        this.$swal({
          title: this.$t('delivery.lang_error'),
          icon: "error",
          text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater'),
        });
      }).finally(()=>{
        this.loading=false;
      })
    },
    addAddress(address){
        this.loadingAdd=true;
        this.axios.post('localbee/v2/account/address',{
          street:address.street,
          streetNumber:address.streetNumber,
          floor:address.floor,
          city:address.city,
          zip:address.zip,
          default:address.default,
        }).then(res=>{
          if(res.status===200 || res.status===201){
            this.getUserAddress()
          }
        }).catch(err=>{
          if(err.status===500){
            this.$swal({
              title: this.$t('delivery.lang_error'),
              icon: "error",
              text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater'),
            });
            return;
          }
          if(err.data) {
            this.$swal({
              title: this.$t('delivery.lang_error'),
              icon: "error",
              text: err.data.message,
            });
          }else {
            this.$swal({
              title: this.$t('delivery.lang_error'),
              icon: "error",
              text: this.$t('delivery.lang_somethingWentWrong'),
            });
          }
        }).finally(()=>{
          this.loadingAdd=false;
        })
    },
    updateAddress(address){
      this.loadingUp=true;
      this.axios.put('localbee/v2/account/address/'+address.id,{
        street:address.street,
        streetNumber:address.streetNumber,
        floor:address.floor,
        city:address.city,
        zip:address.zip,
      }).then(res=>{
        if(res.status===200 || res.status===201){
          this.getUserAddress()
        }
      }).catch(err=>{
        if(err.status===500){
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater'),
          });
          return;
        }
        if(err.data) {
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: err.data.message,
          });
        }else {
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: this.$t('delivery.lang_somethingWentWrong'),
          });
        }
      }).finally(()=>{
        this.loadingUp=false;
      })
    },
    setDefault(address){
      if(address && !address.id){
        return;
      }
      this.loadingUp=true;
      this.axios.put('localbee/v2/account/address/'+address.id+'/setDefault',{
      }).then(res=>{
        if(res.status===200 || res.status===201){
          this.getUserAddress()
        }
      }).catch(err=>{
        if(err.status===500){
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater'),
          });
          return;
        }
        if(err.data) {
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: err.data.message,
          });
        }else {
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: this.$t('delivery.lang_somethingWentWrong'),
          });
        }
      }).finally(()=>{
        this.loadingUp=false;
      })
    },
  },
  mounted() {
    this.getUserAddress()
  }
}
</script>

<style scoped>

</style>