<template>
  <div>
    <!--Start account-component-->
    <account-component />
    <!--End account-component-->
  </div>
</template>

<script>
//import components
import AccountComponent from "@/components/Account/AccountComponent.vue";

export default {
  name: "Account",

  components: { AccountComponent },
};
</script>
