<template>
  <div>
    <h6
        class="text-left justify-start body-1 font-weight-bold px-3"
    >
      {{ $t('delivery.lang_account_noOrdersYet') }}
    </h6>

    <v-card flat>
      <v-item-group>
        <v-container>
          <v-row>
            <v-col v-for="order in orders" :key="order.uuid" cols="12">
              <v-item v-slot="{ active, toggle }">
                <div >
                  <v-card
                      elevation="2"
                      outlined
                      color="transparent"
                      class="align-center pa-0"
                  >
                    <v-card-text
                        style="cursor: pointer;"
                        class="align-center pa-0" @click="toggle"
                    >
                      <!--***-->
                      <v-list-item two-line>
                        <!--start item image-->
                        <v-list-item-avatar tile size="60">
                          <v-img contain
                              :src="order.logo_image"
                              width="100%"
                          />
                        </v-list-item-avatar>
                        <!--end item image-->

                        <v-list-item-content class="align-center">
                          <div
                              class="d-flex justify-space-around align-center"
                          >
                            <div>
                              <!--start item name-->
                              <v-list-item-title
                                  class="font-weight-medium body-2"
                              >
                                {{ order.restaurantName }}
                              </v-list-item-title>
                              <!--end item name-->

                              <!--start order date and time-->
                              <v-list-item-subtitle>
                                {{getFullDate(order.date)}} {{$t('delivery.lang_oclock')}}
                              </v-list-item-subtitle>
                              <!--end order date and time-->

                              <!--start adresse-->
                              <v-list-item-subtitle>
                                {{  }}
                              </v-list-item-subtitle>
                              <!--end adresse-->
                            </div>

                            <!--start item price-->
                            <div>
                              <h6
                                  class="accent--text font-weight-bold body-2"
                              >
                                {{order.total | currency}}
                              </h6>
                            </div>
                          </div>

                          <!--end item price-->
                        </v-list-item-content>

                        <!--start close and open or plus button-->
                        <v-list-item-action>
                          <v-btn
                              elevation="0"
                              dark
                              rounded
                              small
                              color="green"
                              class="px-6 text-capitalize" @click="showRatings(order)"
                          >
                            {{$t('delivery.lang_evaluate')}}
                          </v-btn>
                        </v-list-item-action>
                        <!--start close and open or plus button-->
                      </v-list-item>
                      <!--***-->
                    </v-card-text>

                    <v-expand-transition>
                      <v-card-text
                          v-if="active"
                          class="border-top px-5 tertiary lighten-3"
                      >
                        <v-row no-gutters>
                          <!--start item ordered-->
                          <v-col
                              cols="12"
                              class="mx-auto text-left"
                          >
                            <v-list dense tile elevation="0" color="transparent" >
                              <template v-for="(item,index) in order.items">
                                <v-list-item v-if="!item.randomPositionID.includes('$deliveryCost_')"
                                             dense
                                             :two-line="item.selectedExtras.length>0"
                                             :key="item.randomPositionID"
                                             class="caption"
                                >
                                  <v-list-item-content >
                                    <v-list-item-title><strong>{{item.amount}}x </strong> {{item.name}}</v-list-item-title>
                                    <v-list-item-subtitle v-if="item.selectedExtras.length>0">
                                    <span v-for="(extra,key) in item.selectedExtras" :key="item.randomPositionID+(key*index)">
                                      {{extra.amount}}x {{extra.name}},
                                    </span>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>

                            </v-list>

                            <v-divider></v-divider>
                          </v-col>
                          <!--end item ordered-->

                          <v-col cols="12">
                            <div
                                class="d-flex justify-space-between"
                            >
                              <div>
                                <p v-if="order.isDelivery" class="caption">{{$t('delivery.lang_delivery')}}:</p>

                                <p class="caption font-weight-bold">
                                  {{$t('delivery.lang_paidWith')}} {{order.paymentType}}
                                </p>
                              </div>

                              <div>
                                <p class="caption font-weight-bold" v-if="order.isDelivery">{{ deliveryCost(order) | currency }}</p>

                                <p class="caption font-weight-bold">
                                  {{order.total | currency}}
                                </p>
                              </div>
                            </div>

                            <div
                                class="d-flex justify-space-between"
                            >
                              <div class="caption ">
                                {{$t('delivery.lang_yourOrderNumber')}}: {{order.localbeeOrderId}}
                              </div>

                              <!--start Nachbestellen button-->
                              <div>
                                <v-btn
                                    small
                                    elevation="0"
                                    class="px-6 justify-end"
                                    color="accent text-capitalize"
                                >
                                  {{$t('delivery.lang_reorder')}}
                                </v-btn>
                              </div>
                              <!--end Nachbestellen button-->
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>
                </div>
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
    </v-card>
    <v-card flat v-if="loading" >
      <v-row>
        <v-col cols="12" align="center">
          <v-progress-circular color="primary" indeterminate size="100"></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>

    <v-card v-if="loading===false && orders !== null && orders.length<1" flat class="text-center mx-auto" width="60%">
      <v-sheet elevation="5" class="pt-8 ml-2 mb-1">
        <div class="caption font-weight-bold">
          {{$t('delivery.lang_youHaventPlacedAnOrderYet')}}
        </div>
        <div class="caption">
          {{$t('delivery.lang_hereYouCanSeeYourHistoryAfterYourFirstOrder')}}
        </div>

        <v-img src="@/assets/img/3747371.jpg" />
      </v-sheet>
    </v-card>
    <v-dialog v-model="showRatingsDialog" v-if="selectedOrder" max-width="600px" >
      <v-card >
        <v-card-title>
          <h4 class="text-center mx-auto">{{$t('delivery.lang_rateYourOrder')}}</h4>
          <br/>
          <v-card-text
              style="cursor: pointer;"
              class="pa-0"
          >
            <!--***-->
            <v-list-item two-line>
              <!--start item image-->
              <v-list-item-avatar tile size="80">
                <img
                    :src="selectedOrder.logo_image"
                    alt="item image" width="100%"
                />
              </v-list-item-avatar>
              <!--end item image-->

              <v-list-item-content >
                <div
                    class="d-flex"
                >
                  <div>
                    <!--start item name-->
                    <v-list-item-title
                        class="font-weight-medium body-2"
                    >
                      Pinocchio Kiel
                    </v-list-item-title>
                    <!--end item name-->

                    <!--start order date and time-->
                    <v-list-item-subtitle>
                      {{getFullDate(selectedOrder.date)}} {{$t('delivery.lang_oclock')}}
                    </v-list-item-subtitle>
                    <!--end order date and time-->

                    <!--start adresse-->
                    <v-list-item-subtitle>
                      Schauenburgerstr. 116
                    </v-list-item-subtitle>
                    <!--end adresse-->
                  </div>
                </div>

                <!--end item price-->
              </v-list-item-content>

              <v-list-item-action>
                <div>
                  <h6
                      class="accent--text font-weight-bold body-2"
                  >
                    {{selectedOrder.total | currency}}
                  </h6>
                </div>
              </v-list-item-action>
            </v-list-item>
            <!--***-->
          </v-card-text>

        </v-card-title>
        <v-card-text>
          <v-divider/>
          <h6 class="font-weight-bold">{{$t('delivery.lang_howDidYouLikeYourFood')}}</h6>
          <v-rating
              v-model="feedback.food"
              length="5" clearable
          >
            <template v-slot:item="props" >
              <v-btn @click="props.click" depressed
                  fab  class="ma-2"
                  :color="props.isFilled ? 'green' : ''" style="font-size:25px" v-html="`${emojies[props.index].code}`"
              >

              </v-btn>
            </template>
          </v-rating>
          <h6 class="font-weight-bold">{{$t('delivery.lang_howWasTheDeliveryTime')}}</h6>
          <v-rating
              v-model="feedback.deliveryTime"
              length="5" clearable
          >
            <template v-slot:item="props" >
              <v-btn @click="props.click" depressed
                  fab  class="ma-2"
                  :color="props.isFilled ? 'green' : ''" style="font-size:25px" v-html="`${emojies[props.index].code}`"
              >

              </v-btn>
            </template>
          </v-rating>
          <h6 class="font-weight-bold">{{$t('delivery.lang_howWasOurStaff')}}</h6>
          <v-rating
              v-model="feedback.staff"
              length="5" clearable
          >
            <template v-slot:item="props" >
              <v-btn @click="props.click" depressed
                  fab  class="ma-2"
                  :color="props.isFilled ? 'green' : ''" style="font-size:25px" v-html="`${emojies[props.index].code}`"
              >

              </v-btn>
            </template>
          </v-rating>
          <v-textarea v-model="feedback.feedbackText" rows="5" :placeholder="$t('delivery.lang_leaveCommentAboutTheOrder')" no-resize hide-details dense outlined background-color="grey lighten-4" >
          </v-textarea>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
              elevation="0" depressed
              class="px-6 "
              color="accent text-capitalize" @click="rate" :loading="loadingRatings"
          >
            {{$t('delivery.lang_send')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "OrderHistory",
  components:{},
  data(){
    return{
      orders:null,
      loading:false,
      showRatingsDialog:false,
      loadingRatings:false,
      selectedOrder:null,
      copied: false,
      dialog: false,
      feedback:{
        orderUUID:null,
        food:0,
        deliveryTime:0,
        staff:0,
        feedbackText:null,
      },
      emojies:[
        {
          name:'Sehr schlecht',
          code:'<span>&#128553;<span>',
        },
        {
          name:'Schlecht',
          code:'<span>&#128577;<span>',
        },
        {
          name:'Okay',
          code:'<span>&#128528;<span>',
        },
        {
          name:'Gut',
          code:'<span>&#128522;<span>',
        },
        {
          name:'Sehr gut',
          code:'<span>&#128516;<span>',
        },
      ]
    }
  },
  watch:{
    showRatingsDialog(val){
      if(val){
        this.getRatings();
      }
    }
  },
  methods:{
    getOrders(){
      this.loading = true;

      this.axios.get(ENDPOINTS.ACCOUNT.ORDER.HISTORY).then(res => {
        if (res.status === 200) {
          this.orders = res.data
        }
      }).catch(err => {
        if(err.status===500){
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater'),
          });
          return;
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    getRatings(){
      this.loadingRatings = true;

      this.axios.get(ENDPOINTS.ACCOUNT.ORDER.FEEDBACK.GET,{
        orderUUID:this.selectedOrder.uuid
      }).then(res => {
        if (res.status === 200) {
          this.feedback.food=res.data.food
          this.feedback.deliveryTime=res.data.deliveryTime
          this.feedback.staff=res.data.staff
          this.feedback.feedbackText=res.data.feedbackText
        }
      }).catch(err => {
        if(err.status===500){
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater'),
          });
          return;
        }
      }).finally(() => {
        this.loadingRatings = false;
      })
    },
    getFullDate(timestamp){
      return moment.unix(timestamp).format('DD.MM.YYYY, HH:mm')
    },
    deliveryCost(order){
      if(order.isDelivery){
        return order.items.find(item=>item.randomPositionID.includes('$deliveryCost_')).sellPrice
      }
      return 0
    },
    showRatings(order){
      this.feedback.food=null;
      this.feedback.deliveryTime=null;
      this.feedback.staff=null;
      this.feedback.feedbackText=null;
      this.selectedOrder=order;
      this.showRatingsDialog=true;
    },
    rate(){
      this.loadingRatings = true;

      this.axios.post(ENDPOINTS.ACCOUNT.ORDER.FEEDBACK.PUT,{
            orderUUID:this.selectedOrder.uuid,
            food:this.feedback.food,
            deliveryTime:this.feedback.deliveryTime,
            staff:this.feedback.staff,
            feedbackText:this.feedback.feedbackText,
          }).then(res => {
        if (res.status === 200) {
          this.orders = res.data
        }
      }).catch(err => {
        if(err.status===500){
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater'),
          });
          return;
        }
      }).finally(() => {
        this.loadingRatings = false;
      })
    }
  },
  mounted() {
    this.getOrders();
  }
}
</script>

<style scoped>

</style>