<template>
  <div>
    <v-container fluid class="pt-16" width="100%" :style="{minHeight:this.$vuetify.breakpoint.xs?'600px':this.$vuetify.breakpoint.sm?'760px':this.$vuetify.breakpoint.mdAndUp?'800px':'900px'}">
      <v-card tile elevation="0" class="pt-2 pb-16" width="100%">
        <v-toolbar
          flat
          color="transparent"
          width="100%"
        >
          <v-toolbar-items>
            <v-hover v-slot="{ hover }" v-if="!loadingImage" >
              <v-avatar color="accent" size="75" @click="showFilePicker" >
                <h3 v-if="$store.state.auth.userName" class="text-white">{{$store.state.auth.userName.substring(0,1)}}</h3>
                <img v-if="imageSRC" :src="imageSRC" alt="John"  />
                <v-overlay
                    absolute
                    :value="hover"
                >
                  <font-awesome-icon size="2x" :icon="['fal','camera']" />
                </v-overlay>
                <input type="file"
                       ref="file" name="image"
                       @change="updateUserImage($event.target.files)"
                       style="display:none">
              </v-avatar>
            </v-hover>
            <v-skeleton-loader v-else  type="avatar" :types="{avatar:'avatar'}"  min-height="100" min-width="100" >

            </v-skeleton-loader>
          </v-toolbar-items>

          <v-toolbar-title class="text-md-h5 font-weight-bold ml-6">
            {{$store.state.auth.userName}}
          </v-toolbar-title>

          <v-spacer  />
          <v-btn color="accent" @click="$router.go(-1)" >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </v-toolbar>
      </v-card>

      <v-card tile elevation="0">
        <v-row>
          <v-col cols="12" sm="12" md="4"  >
            <h6
              class="text-left justify-start text-muted body-1 font-weight-bold mr-16"
            >
              {{ $t('delivery.lang_account_myAccount') }}
            </h6>

            <v-sheet elevation="5">
              <v-tabs
                v-model="tab"
                background-color="transparent"
                color="accent"
                slider-color="transparent"
                vertical
              >
                <v-tab
                  v-for="item in items"
                  :key="item.tab"
                  active-class="grey lighten-2"
                  class="text-left justify-start text-capitalize"
                >
                  {{ item.tab }}
                </v-tab>
              </v-tabs>
            </v-sheet>
          </v-col>

          <v-col cols="12" sm="12" md="8"  >
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <customer-data ref="customerD"/>
              </v-tab-item>

              <v-tab-item>
                <customer-addresses/>
              </v-tab-item>

              <v-tab-item>
                <OrderHistory/>
              </v-tab-item>

              <v-tab-item v-if="false">
                <h6
                  class="text-left justify-start body-1 font-weight-bold px-3"
                >
                  {{ $t('delivery.lang_account_myPoints') }}
                </h6>

                <v-card flat> </v-card>
              </v-tab-item>

              <v-tab-item>
                <FavoriteRestaurants/>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
//import components
//import Account from "@/components/Account/Account.vue";

import CustomerAddresses from "@/components/Account/CustomerAddresses";
import CustomerData from "@/components/Account/CustomerData";
import OrderHistory from "@/components/Account/OrderHistory";
import FavoriteRestaurants from "./FavoriteRestaurants";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {library} from "@fortawesome/fontawesome-svg-core"
import {
  faCamera
} from "@fortawesome/pro-light-svg-icons";

library.add(faCamera)

export default {
  name: "AccountComponent",
  components: {FavoriteRestaurants, OrderHistory, CustomerData, CustomerAddresses,"font-awesome-icon":FontAwesomeIcon},

  data() {
    return {
      loading:false,
      loadingImage:false,
      image:null,
      src:null,
      tab: 2,
      items: [
        { tab: this.$t('delivery.lang_account_personalData'), content: "Tab 1 Content" },
        { tab: "Adresse", content: "Tab 2 Content" },
        { tab: this.$t('delivery.lang_account_myOrders'), content: "Tab 3 Content" },
        //{ tab: "Punkte", content: "Tab 4 Content" },
        { tab: this.$t('delivery.lang_account_myFavorite'), content: "Tab 5 Content" },
      ],
    };
  },
  computed:{
    imageSRC(){
      if(!this.src)
        return false;

      fetch(this.src, { method: 'HEAD' })
          .then(res => {
            if (!res.ok) {
              return this.src
            } else {
              return false;
            }
          }).catch(err => false);

    }
  },
  watch:{
  },
  methods:{
    showFilePicker(){
      this.$refs.file.click();
    },
    async updateUserImage(file) {

      if (Object.values(file).length<1) {
        return;
      }

      let imageFile = file[0]

      if (!imageFile.type.match('image.*')) {
        this.$swal({
          title: this.$t('delivery.lang_error'),
          icon: "error",
          text: this.$t('delivery.lang_pleaseSelectValidImage'),
        });
        return;
      }
      this.loading = true;
      await this.getBase64(imageFile).then(data=>this.image=data)
      this.axios.post(ENDPOINTS.ACCOUNT.INFO.UPDATEIMAGE,{
            image: this.image
          }).then(res => {
        if (res.status === 200) {
          this.$swal({
            title: this.$t('delivery.lang_success'),
            icon: "success",
            text: this.$t('delivery.lang_dataUpdatedSuccessfully'),
          });
          this.getUserData();
        }else{
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater'),
          });
        }
      }).catch(err => {

      }).finally(() => {
        this.loading = false;
      })
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async getUserData() {
      this.loadingImage = true;

      this.axios.get(ENDPOINTS.ACCOUNT.INFO.GET).then(res => {
        if (res.status === 200) {
          this.src=res.data.image;
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.loadingImage = false;
      })
    },
  },
  mounted() {
    this.getUserData()
  }
};
</script>

<style scoped>
.v-tab {
  max-width: 100% !important;
}
</style>
