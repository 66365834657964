<template>
  <div>
    <h6
        class="text-left justify-start body-1 font-weight-bold px-3"
    >
      {{$t('delivery.lang_account_myFavorite')}}
    </h6>

    <v-card flat>
      <v-item-group>
        <v-container>
          <v-row>
            <v-col v-for="(res,index) in favorites" :key="index" cols="12">
              <v-item v-slot="{ active, toggle }">
                <div>
                  <v-card
                      elevation="2"
                      outlined
                      color="transparent"
                      class="align-center pa-0"
                  >
                    <v-card-text
                        style="cursor: pointer;"
                        class="align-center pa-0" @click="toggle"
                    >
                      <!--***-->
                      <v-list-item two-line>
                        <!--start item image-->
                        <v-list-item-avatar tile size="60">
                          <v-img contain
                              :src="res.restaurantLogo"
                              width="100%"
                          />
                        </v-list-item-avatar>
                        <!--end item image-->

                        <v-list-item-content class="align-center">
                          <div
                              class="d-flex justify-space-around align-center"
                          >
                            <div>
                              <!--start item name-->
                              <v-list-item-title
                                  class="font-weight-medium body-2"
                              >
                                {{ res.restaurantName }}
                              </v-list-item-title>
                              <!--end item name-->

                              <!--start order date and time-->
                              <v-list-item-subtitle>
                              </v-list-item-subtitle>
                              <!--end order date and time-->

                              <!--start adresse-->
                              <v-list-item-subtitle v-html="res.restaurantDescription">
                              </v-list-item-subtitle>
                              <!--end adresse-->
                            </div>

                            <!--start item price-->
                            <div>
                              <h6
                                  class="accent--text font-weight-bold body-2"
                              >
                              </h6>
                            </div>
                          </div>

                          <!--end item price-->
                        </v-list-item-content>
                      </v-list-item>
                      <!--***-->
                    </v-card-text>
                  </v-card>
                </div>
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
    </v-card>

    <v-card flat v-if="loading" >
      <v-row>
        <v-col cols="12" align="center">
          <v-progress-circular color="primary" indeterminate size="100"></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>

    <v-card v-if="loading===false && favorites && favorites.length<1" flat class="text-center mx-auto" width="60%">
      <v-sheet elevation="5" class="pt-8 ml-2 mb-1">
        <div class="caption font-weight-bold">
          {{$t('delivery.lang_account_noFavoritesYet')}}
        </div>
        <div class="caption">
          {{$t('delivery.lang_FavoritesAppearHere')}}
        </div>

        <v-img src="@/assets/img/searcher.svg" />
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "FavoriteRestaurants",
  components:{},
  data(){
    return{
      favorites:null,
      loading:false,
    }
  },
  computed:{

  },
  methods:{
    getFavRestaurants(){
      this.loading = true;

      this.axios.get(ENDPOINTS.ACCOUNT.FAV).then(res => {
        if (res.status === 200) {
          this.favorites = res.data;
        }
      }).catch(err => {

      }).finally(() => {
        this.loading = false;
      })
    }
  },
  mounted() {
    this.getFavRestaurants();
  }
}
</script>

<style scoped>

</style>
