export default {
    data() {
        return {
            rules: {
                required: (v) => !!v || this.$t("delivery.lang_required"),
                mobile: (v) => (!!v && v.length <= 10) || this.$t("generic.lang_phoneMustBeLessThan10Characters"),
                //(v) => !isNaN(v),
                email: (value) => {
                    const pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                    return pattern.test(value) || this.$t("generic.lang_emailRule");
                },
            }
        }
    },
    methods: {
        checkPattern(event) {
            if ((new RegExp(/[^A-Za-z0-9-аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ. ¨äëïüöÄËÏÖÜß]+/g).test(event.key)))
                event.preventDefault();
        },
    }
}