<template>
<div>
  <h6
      class="text-left justify-start body-1 font-weight-bold px-3"
  >
    {{$t('delivery.lang_account_personalData')}}
  </h6>

  <v-card flat :loading="loading">
    <v-row no-gutters>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="px-3">
        <span class="caption">{{this.$t('delivery.lang_Salutation')}}</span>
        <div>
          <v-select
              v-model="salutation"
              :items="salutationItems"
              label="" item-text="text" item-value="value"
              outlined
              dense
          ></v-select>
        </div>
      </v-col>

      <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="6"
          style="margin-top: -25px;"
          class="px-3"
      ></v-col>

      <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="6"
          style="margin-top: -25px;"
          class="px-3"
      >
        <span class="caption">{{this.$t('delivery.lang_firstName')}}</span>
        <div>
          <v-text-field
              v-model="firstname"
              @keypress="checkPattern"
              name="name"
              label=""
              outlined
              dense :loading="loading"
          ></v-text-field>
        </div>
      </v-col>

      <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="6"
          style="margin-top: -25px;"
          class="px-3"
      >
        <span class="caption">{{this.$t('delivery.lang_lastName')}}</span>
        <div>
          <v-text-field
              v-model="lastname"
              @keypress="checkPattern"
              name="name"
              label=""
              outlined
              dense :loading="loading"
          ></v-text-field>
        </div>
      </v-col>

      <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="6"
          style="margin-top: -25px;"
          class="px-3"
      >
        <span class="caption">E-Mail</span>
        <div>
          <v-text-field
              v-model="email"
              name="name"
              label=""
              outlined
              dense :loading="loading"
          ></v-text-field>
        </div>
      </v-col>

      <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="6"
          style="margin-top: -25px;"
          class="px-3"
      >
        <span class="caption">{{this.$t('delivery.lang_phoneNuber')}}</span>
        <div>
          <v-text-field
              v-model="mobile"
              name="name"
              label=""
              outlined
              dense :loading="loading"
          ></v-text-field>
        </div>
      </v-col>

      <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="6"
          style="margin-top: -25px;"
          class="px-3"
      >
        <span class="caption">{{$t('delivery.lang_account_birthday')}}</span>
        <div>
          <v-text-field
              v-model="birthDate"
              name="name"
              label="" type="date"
              outlined
              dense :loading="loading"
          ></v-text-field>
        </div>
      </v-col>

      <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="6"
          style="margin-top: -25px;"
          class="px-3"
      >
        <span class="caption">{{this.$t('delivery.lang_companyNumber')}}</span>
        <div>
          <v-text-field
              v-model="company"
              name="name"
              label=""
              outlined
              dense :loading="loading"
          ></v-text-field>
        </div>
      </v-col>

      <v-col
          cols="12"
          class="px-3 text-right justify-end"
      >
        <v-btn
            color="accent" @click="updateUserData"
            class="text-capitalize text-right justify-end" :loading="loading"
        >
          {{$t('delivery.lang_account_saveData')}}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h6
            class="text-left justify-start body-1 font-weight-bold px-3"
        >
          {{this.$t('delivery.lang_password')}}
        </h6>
      </v-col>
      <form ref="passwordForm" style="width:100%" >
      <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="6"
          style="margin-top: -25px;"
          class="px-3"
      >
        <span class="caption">{{ $t('delivery.lang_account_currentPassword') }}</span>
        <v-text-field
            ref="password"
            v-model="password"
            placeholder="*********"
            type="password"
            outlined
            dense :loading="loadingPassword"
            required
        ></v-text-field>
        <span class="caption">{{$t('delivery.lang_account_newPassword')}}</span>
        <v-text-field
            ref="password"
            v-model="newPassword"
            placeholder="*********"
            type="password"
            outlined
            dense :loading="loadingPassword"
            required
        ></v-text-field>
        <span class="caption">{{$t('delivery.lang_account_repeatNewPassword')}}</span>
        <v-text-field
            ref="password"
            v-model="newPasswordRep"
            placeholder="*********"
            type="password"
            outlined :rules="[v=>newPassword===v || $t('delivery.lang_account_repeatTheSameNewPassword')]"
            dense :loading="loadingPassword"
            required
        ></v-text-field>
      </v-col>
      </form>
      <v-col
          cols="12"
          class="px-3 text-right justify-end"
      >
        <v-btn
            color="accent" @click="updatePassword"
            class="text-capitalize text-right justify-end" :loading="loadingPassword"
        >
          {{$t('lang_account_savePassword')}}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</div>
</template>

<script>
import validation from "../../mixins/validation";

export default {
  name: "CustomerData",
  mixins:[validation],
  data(){
    return{
      salutationItems: [
        {
          text: this.$t('delivery.lang_woman'),
          value: "w",
        },
        {
          text: this.$t('delivery.lang_mr'),
          value: "m",
        },
        {
          text: this.$t('delivery.lang_family'),
          value: "f",
        },
        {
          text: this.$t('delivery.lang_miscellaneous'),
          value: "d",
        },
      ],
      salutation:null,
      firstname:null,
      lastname:null,
      email:null,
      mobile:null,
      birthDate:null,
      company:null,
      password:null,
      newPassword:null,
      newPasswordRep:null,
      loading:false,
      loadingPassword:false,
    }
  },
  methods:{
    async getUserData() {
      this.loading = true;

      this.axios.get(ENDPOINTS.ACCOUNT.INFO.GET).then(res => {
        if (res.status === 200) {
          this.salutation=res.data.salutation
          this.firstname=res.data.firstname
          this.lastname=res.data.lastname
          this.email=res.data.email
          this.mobile=res.data.mobile
          this.birthDate=res.data.birthday
          this.company=res.data.company
          Promise.resolve(res.image);
        }else{
          Promise.reject();
        }
      }).catch(err => {
        if(err.status===500){
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater'),
          });
          return;
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    updateUserData() {
      this.loading = true;

      this.axios.post(ENDPOINTS.ACCOUNT.INFO.UPDATE,{
        salutation:this.salutation,
        firstname:this.firstname,
        lastname:this.lastname,
        email:this.email,
        mobile:this.mobile,
        birthday:this.birthDate,
        company:this.company,
      }).then(res => {
        if (res.status === 200) {
          this.$swal({
            title: this.$t('delivery.lang_success'),
            icon: "success",
            text: this.$t('delivery.lang_dataUpdatedSuccessfully'),
          });
        }else{
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater'),
          });
        }
      }).catch(err => {
        if(err.status===500){
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater'),
          });
          return;
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    updatePassword() {
      this.loadingPassword = true;

      this.axios.post(ENDPOINTS.ACCOUNT.INFO.CHANGEPASSWORD,{
        current:this.password,
        new:this.newPassword
      }).then(res => {
        if (res.status === 200) {
          this.$swal({
            title: this.$t('delivery.lang_success'),
            icon: "success",
            text: this.$t('delivery.lang_dataUpdatedSuccessfully'),
          });
        }else{
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: res.data.message,
          });
        }
      }).catch(err => {
        if(err.status===500){
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater'),
          });
          return;
        }
      }).finally(() => {
        this.$refs.passwordForm.reset()
        this.loadingPassword = false;
      })
    },
  },
  mounted() {
    this.getUserData()
  }
}
</script>

<style scoped>

</style>